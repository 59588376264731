<template>
  <top-bar title="Custos" back-to="/home" />
  <div class="bg-gray-300 w-full h-full overflow-auto">
    <div
      class="pt-20 cost-grid-template grid gap-4 p-4 w-full items-center justify-center justify-self-center"
    >
      <div
        v-for="cost in costsNames"
        :key="cost.id"
        class="flex items-center justify-center"
      >
        <router-link
          :to="`/costs/${cost.table}`"
          class="flex flex-col items-center justify-center rounded-2xl bg-veturi-main text-veturi-white w-40 h-36"
        >
          <!-- <icon
            name="currency-dollar"
            size="40"
            class="w-full m-2"
          /> -->
          <p class="text-center text-2xl font-bold">{{ cost.name }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { auth } from '@/services'
import TopBar from '@/components/TopBar'
// import Icon from '@/components/Icon'

export default {
  components: { TopBar },

  setup (props) {
    const store = useStore()
    const costsNames = computed(() => store.state.costsNames)
    const costs = computed(() => store.state.costs)

    store.dispatch('getCostsNames')

    // onMounted(async () => {
    // store.dispatch('getAllCosts')
    // })

    async function logout () {
      await auth.logout()
    }

    return {
      logout,
      costsNames,
      costs
    }
  }
}
</script>

<style scoped>
.cost-grid-template {
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-template-rows: 1fr;
}
</style>
